import { lazy } from 'react'
import themes from './themes'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOJHdQ6kCtkvpBA1TrxO5SG2kUb03huOE",
  authDomain: "monstersquad-9bf05.firebaseapp.com",
  projectId: "monstersquad-9bf05",
  storageBucket: "monstersquad-9bf05.appspot.com",
  messagingSenderId: "29815906516",
  appId: "1:29815906516:web:200c04b15d17d3813c9b41",
  measurementId: "G-PKD5JKH90B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const config = {
  menu: {
    MenuHeader: lazy(() =>
      import('material-ui-shell/lib/components/MenuHeader/MenuHeader')
    ),
  },
  theme: {
    themes,
    defaultThemeID: 'default',
    defaultType: 'light',
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound')),
  },

}

export default config
